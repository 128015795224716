// Other
export const PARAMETER_NAME_ORIGIN = "";
export const PARAMETER_NAME_STORAGE_TOKEN_KEY = "bearer_token";
export const PARAMETER_NAME_STORAGE_CODE_VERIFIER = "code_verifier";

// Query parameters
export const PARAMETER_NAME_CONTINUE_TO = "continueTo";
export const PARAMETER_NAME_RETURN_TO = "returnUrl";
export const PARAMETER_NAME_REDIRECT_URI = "redirect_uri";
export const PARAMETER_NAME_ENCODED_PARAMETERS = "encodedParams";
export const PARAMETER_NAME_REQUEST_TO_JOIN = "requestToJoin";
export const PARAMETER_NAME_INVITATION_CODE = "invitation";
export const PARAMETER_NAME_AUTHZ_HANDLER = "authzHandler";
export const PARAMETER_NAME_SKIP_OPTIONAL_REDIRECTS = "skipOptionalRedirects";
export const PARAMETER_NAME_START_LOGIN_FLOW = "startLogin";

// Login paths
export const PARAMETER_NAME_LOGIN_CLIENT_ID = window.ENV.OAUTH_CLIENT_ID;
export const PARAMETER_NAME_LOGIN_REDIRECT_URI = window.ENV.OAUTH_LOGIN_REDIRECT_URI;
export const PARAMETER_NAME_LOGIN_PATH = `${PARAMETER_NAME_ORIGIN}/oauth2/authz/?redirect_uri=${encodeURIComponent(PARAMETER_NAME_LOGIN_REDIRECT_URI)}&response_type=code&client_id=${PARAMETER_NAME_LOGIN_CLIENT_ID}`;
export const PARAMETER_NAME_LOGOUT_PATH = `${PARAMETER_NAME_ORIGIN}/logout/?triggerSingleSignout=true`;
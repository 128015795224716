import { PARAMETER_NAME_STORAGE_TOKEN_KEY, PARAMETER_NAME_ORIGIN } from "../constants";

const doAuthorizedGetRequest = async (path: string) => {
    //
    return await sendRequest(path, "GET", new Headers(), true);
}

const doAuthorizedPostRequest = async (path: string, contentType?: string, payload?: string) => {
    //
    const headers = new Headers();
    headers.append("Content-Type", contentType ? contentType : "application/json");
    return await sendRequest(path, "POST", headers, true, payload);
}

const doAuthorizedPutRequest = async (path: string, payload?: string) => {
    //
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return await sendRequest(path, "PUT", headers, true, payload);
}

const sendRequest = async (
    path: string,
    method: string,
    headers: Headers,
    requireAuthorization: boolean,
    body?: string) => {
    //
    if (requireAuthorization) {
        //
        let token = sessionStorage.getItem(PARAMETER_NAME_STORAGE_TOKEN_KEY);
        if (!token) {
            //
            return new Response("No token", { status: 401, statusText: "No token" });
        }
        headers.append("Authorization", `Bearer ${token}`);
    }
    //
    return await fetch(PARAMETER_NAME_ORIGIN + path, {
        method: method,
        redirect: 'follow',
        credentials: "include",
        body: body,
        headers: headers
    }).then(async res => {
        return res;
    }).catch(err => {
        throw new Response(err, { status: 500, statusText: err.statusText });
    });
}

export const getUser = async () => {
    return await doAuthorizedGetRequest("/api/account/v1/users/me");
}

export const updateUser = async (json: string) => {
    return await doAuthorizedPutRequest("/api/account/v1/users/me", json);
}

export const getOnlineProfile = async () => {
    return await doAuthorizedGetRequest("/api/account/v1/users/me/online-profile");
}

export const updateOnlineProfile = async (json: string) => {
    return await doAuthorizedPutRequest("/api/account/v1/users/online-profile", json);
}

export const getOrganizationGroups = async () => {
    return await doAuthorizedGetRequest("/api/account/v1/users/me/organization-groups");
}

export const getOrganizationRoles = async () => {
    return await doAuthorizedGetRequest("/api/account/v1/users/me/organization-roles");
}

export const getMembershipRequests = async () => {
    return await doAuthorizedGetRequest("/api/account/v1/users/me/membership-requests");
}

export const getSuggestedOrganizations = async () => {
    return await doAuthorizedGetRequest("/api/account/v1/users/me/suggested-organizations");
}

export const getPendingInvitations = async (invitationCode?: string | null) => {
    return await doAuthorizedGetRequest(`/api/account/v1/users/me/pending-invitations${invitationCode ? '?invitationCode=' + invitationCode : ''}`);
}

export const getOrganizationAdmins = async (organizationId: string) => {
    return await doAuthorizedGetRequest(`/api/account/v1/organizations/${organizationId}/organization-roles/Admin/users`);
}

export const getAvailableLicenses = async () => {
    return await doAuthorizedGetRequest("/api/account/v1/users/me/available-licenses?checkLicense=true");
}

export const acceptInvitation = async (type: string, code: string) => {
    //
    return await doAuthorizedPostRequest(`/api/account/v1/invitations/${type}/${code}/accept`);
}

export const rejectInvitation = async (type: string, code: string) => {
    //
    return await doAuthorizedPostRequest(`/api/account/v1/invitations/${type}/${code}/reject`);
}

export const sendMembershipRequest = async (organizationId: string, groupType?: string) => {
    //
    return await doAuthorizedPostRequest(`/api/account/v1/organizations/${organizationId}/membership-requests${groupType ? `?groupType=${groupType}` : ""}`);
}

export const cancelMembershipRequest = async (organizationId: string, requestId: string) => {
    //
    return await doAuthorizedPostRequest(`/api/account/v1/organizations/${organizationId}/membership-requests/${requestId}/cancel`);
}

export const getAvailableTrials = async () => {
    //
    return await doAuthorizedGetRequest("/api/account/v1/users/me/available-trials");
}

export const startTrial = async (trialId: string) => {
    //
    return await doAuthorizedPostRequest(`/api/account/v1/users/me/available-trials/${trialId}/start`);
}

export const getOrganizationName = async (organizationId: string) => {
    //
    return await sendRequest(`/api/account/v1/organizations/${organizationId}/get-name`, "GET", new Headers(), false);
}

export const synchronizeUserDataWithExternalProvider = async () => {
    //
    return await doAuthorizedPostRequest("/api/account/v1/users/me/synchronize");
}

export const postOauthToken = async (payload: string) => {
    //
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return await sendRequest("/oauth2/access", "POST", headers, false, payload);
}
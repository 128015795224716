import { useLoaderData, useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { useEffect } from 'react';
import CustomButton from '../../custom/CustomButton';
import { Organization } from '../../../model/Organization';
import { PARAMETER_NAME_CONTINUE_TO, PARAMETER_NAME_LOGIN_PATH, PARAMETER_NAME_STORAGE_CODE_VERIFIER } from '../../../assets/constants';
import { useLocalization } from '../../providers/LocalizationProvider';
import pkceChallenge from 'pkce-challenge';

export function RequestMembershipPage() {

  const location = useLocation();
  const navigate = useNavigate();
  const { getTranslation, getTranslationWithParameters } = useLocalization();
  let rootData = useRouteLoaderData('root') as any;
  let user = rootData?.user;
  let organization = useLoaderData() as Organization;

  /**
   * If user is logged in / found, navigate to organization page with parameter to start membership request process.
   */
  useEffect(() => {
    if (user !== null && user !== undefined) {
      navigate({ pathname: `/account/myOrganization.html`, search: location.search });
    }
  }, [])

  const signIn = async () => {
    //
    const challenge = await pkceChallenge();
    sessionStorage.setItem(PARAMETER_NAME_STORAGE_CODE_VERIFIER, challenge.code_verifier);
    let state = btoa(`${PARAMETER_NAME_CONTINUE_TO}=/account/myOrganization.html${location.search}`);
    let loginPath = `${PARAMETER_NAME_LOGIN_PATH}&state=${state}&code_challenge=${challenge.code_challenge}&code_challenge_method=S256`;
    window.location.href = loginPath;
  }

  return (
    <div
      id='landing-page'
      className='flex flex-col gap-8 mt-12 items-center w-full px-20 lg:px-40 xl:px-40 2xl:px-80 mb-8'
    >
      <h1 className='font-semibold text-4xl text-center'>
        {getTranslationWithParameters({
          key: "requestMembership.title",
          parameters: {
            organizationName: organization?.name
          }
        })}
      </h1>
      <p className='text-lg text-center'>{getTranslation("requestMembership.description")}</p>
      <div className='flex flex-col gap-4'>
        <CustomButton
          testdataId='request-membership-signin-button'
          label={getTranslation("requestMembership.signInButton")}
          type='primary'
          customClass='px-8 py-2 font-semibold'
          onClick={() => signIn()}
        />
        <div testdata-id="request-membership-signup-button" onClick={() => signIn()} className='text-sm text-center text-trimble-blue'>{getTranslation("requestMembership.signUpButton")}</div>
      </div>
    </div>
  )
}

export default RequestMembershipPage;
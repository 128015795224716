import { profileType } from '../../../../assets/properties/account/myProfile/profileType';
import { specialization } from '../../../../assets/properties/account/myProfile/profileSpesialization';
import { stateCa, stateUs } from '../../../../assets/properties/account/myProfile/state';
import { User } from '../../../../model/User';
import CustomSelect from '../../../custom/CustomSelect';
import { useLocalization } from '../../../providers/LocalizationProvider';
import { campusDiscipline } from '../../../../assets/properties/account/myProfile/campusDicipline';
import { campusRole } from '../../../../assets/properties/account/myProfile/campusRole';
import { ChangeEvent, useEffect, useState } from 'react';
import CustomInput from '../../../custom/CustomInput';
import { OrganizationGroup } from '../../../../model/OrganizationGroup';
import CustomButton from '../../../custom/CustomButton';
import { useNotification } from '../../../providers/NotificationProvider';
import { updateOnlineProfile } from '../../../../assets/api';
import CustomCheckbox from '../../../custom/CustomCheckbox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PARAMETER_NAME_CONTINUE_TO, PARAMETER_NAME_SKIP_OPTIONAL_REDIRECTS } from '../../../../assets/constants';

export function ProfileColumnProfile({ user, employeesGroup, newUser }: { user: User, employeesGroup: OrganizationGroup, newUser: boolean }) {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { sendNotification } = useNotification();
  const { getTranslation, getTranslationWithParameters } = useLocalization();
  const [form, setForm] = useState<any>(user);
  const [errors, setErrors] = useState<any | null>();
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [toolTipVisible, setToolTipVisible] = useState(false);

  useEffect(() => {
    //
    if (user && user !== form) {
      //
      setForm(user);
    }
  }, [user])

  const handleChange = async (e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (e.target.type === "checkbox") {
      const { checked } = e.target as HTMLInputElement;
      setForm((form: any) => ({ ...form, [name]: checked }));
    } else {
      if (name === "region") {
        setForm((form: any) => ({ ...form, address: { ...form.address, [name]: value } }));
      } else {
        setForm((form: any) => ({ ...form, [name]: value }));
      }
    }
  }

  const onReset = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setForm(user);
  }

  const onSave = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    updateOnlineProfile(JSON.stringify(form)).then(async (obj) => {
      //
      if (204 === obj.status) {
        //success
        setErrors(null);
        sendNotification(getTranslation("account.profile.form.notifications.success"));
        const continueToParam = searchParams.get(PARAMETER_NAME_CONTINUE_TO);
        if (continueToParam) {
          //
          const paramsWithinContinueTo = new URLSearchParams(continueToParam);
          if (paramsWithinContinueTo.has(PARAMETER_NAME_SKIP_OPTIONAL_REDIRECTS)) {
            //
            window.location.href = continueToParam;
            return;
          }
        }
        if (newUser) {
          //
          if (form.type === "PROFESSIONAL") {
            window.location.href = '/account/myOrganization.html' + window.location.search;
            return;
          } else {
            window.location.href = '/account/activateLicenses.html' + window.location.search;
            return;
          }
        } else {
          //
          navigate({ pathname: '.', search: window.location.search }, { replace: true });
          return;
        }
      } else {
        //validation failed
        let result = await obj.json();
        setErrors(result?.childErrors);
        sendNotification(getTranslation("account.profile.form.notifications.failed"), "error");
      }
    }).catch((err) => {
      //
      sendNotification(getTranslation("account.profile.form.notifications.failed"), "error");
    });
  }

  useEffect(() => {
    const validateForm = async () => {
      //
      if (form.address == undefined
        || form.address == null
        || form.address.country == undefined
        || form.address.country == null) return setSaveEnabled(false);
      if (form.termsAndConds === undefined || form.termsAndConds === false) form.termsAndConds = true;
      if (form.type === "PROFESSIONAL") {
        //
        if (employeesGroup === null && (form.organization === null || form.organization === "")) return setSaveEnabled(false);
        if (form.phoneNumber === null || form.phoneNumber === "") return setSaveEnabled(false);
        if (user?.address?.country === "CA" || user?.address?.country === "US") {
          if (!form.address?.region || form.address?.region === null || form.address?.region === "") return setSaveEnabled(false);
        }
        return setSaveEnabled(true);
      }
      if (form.type === "EDUCATIONAL") {
        //
        if (form.educationalRole === null || form.educationalRole === "") return setSaveEnabled(false);
        if (form.educationalDiscipline === null || form.educationalDiscipline === "") return setSaveEnabled(false);
        if (form.educationalOrganization === null || form.educationalOrganization === "") return setSaveEnabled(false);
        if (form.educationalRole === "STUDENT") {
          //
          if (form.ageConfirmation === undefined || form.ageConfirmation === false) return setSaveEnabled(false);
        }
        return setSaveEnabled(true);
      }
      return setSaveEnabled(false);
    }
    validateForm();
  }, [form])

  return (
    <div
      id='profile-column-profile'
      className='flex flex-col gap-8 justify-center h-full md:w-[460px] my-2 mx-4'
    >
      <form
        className='flex flex-col gap-8'
      >
        <div className='flex flex-col space-y-6 md:w-4/6'>
          <CustomSelect
            testdataId="profile-profile-type-select"
            name='type'
            setValue={handleChange}
            label={getTranslation("account.profile.form.profileType.title")}
            translationPrefix='account.profile.form.profileType.options.'
            optionsLabel={getTranslation("account.profile.form.profileType.options.label")}
            options={profileType}
            value={form?.type}
            width='307px'
            required
            icon={(
              <div className="relative">
                <svg
                  testdata-id="profile-profile-type-tooltip"
                  onClick={() => setToolTipVisible(!toolTipVisible)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  className="mi-solid mi-help fill-trimble-blue cursor-pointer hover:fill-orange-300 focus:fill-orange-300"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 16.25a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm2.82-6.72-.77.79c-.42.43-.69.78-.83 1.34-.07.28-.1.58-.1.92v.25H10.9v-.68c0-.41.07-.81.2-1.21.18-.52.48-1.01.87-1.4l1.07-1.09c.35-.33.49-.81.4-1.32-.09-.52-.5-.97-1.01-1.12a1.467 1.467 0 0 0-1.81.93c-.14.44-.51.73-.94.73h-.26c-.31 0-.59-.14-.78-.39-.19-.25-.25-.58-.16-.89a3.72 3.72 0 0 1 2.97-2.61c.17-.03.34-.04.51-.04 1.15 0 2.3.63 3.06 1.68 1.13 1.57.72 3.19-.19 4.1Z" />
                </svg>
                <div id="tooltip-default" role="tooltip" className={`${toolTipVisible ? 'visible' : 'hidden'} absolute left-10 -top-1 px-4 py-4 text-sm font-medium text-white bg-gray-700 rounded-xs shadow-sm w-[240px] h-[100px] flex flex-col justify-evenly`}>
                  <div className="text-sm font-bold">{getTranslation("account.profile.form.profileType.tooltip.title")}</div>
                  <div className="text-xs font-medium">
                    <p>{getTranslation("account.profile.form.profileType.tooltip.description")}</p>
                  </div>
                  <div className="absolute border-t-[8px] border-b-[8px] border-r-[8px] border-white border-r-gray-700 top-1 -left-2 bg-transparent" />
                </div>
              </div>
            )}
            error={errors?.type ? errors.type : null}
          />
          {
            form.type === 'PROFESSIONAL' ?
              <>
                <CustomSelect
                  testdataId="profile-specialization-select"
                  name='specialization'
                  setValue={handleChange}
                  label={getTranslation("account.profile.form.specialization.title")}
                  translationPrefix='account.profile.form.specialization.options.'
                  optionsLabel={getTranslation("account.profile.form.specialization.options.label")}
                  options={specialization}
                  width='307px'
                  value={form?.specialization}
                  error={errors?.specialization ? errors.specialization : null}
                />
                <CustomInput
                  testdataId="profile-company-input"
                  name='organization'
                  setValue={handleChange}
                  label={getTranslation("account.profile.form.company.title")}
                  value={employeesGroup != null ? employeesGroup.organizationName : form?.organization}
                  disabled={employeesGroup != null}
                  required
                  error={errors?.company ? errors.company : null}
                />
                {
                  form.address?.country === "CA" || form.address?.country === "US" ?
                    <CustomSelect
                      testdataId="profile-state-select"
                      name='region'
                      setValue={handleChange}
                      label={getTranslation("account.profile.form.state.title")}
                      translationPrefix={`account.profile.form.state.options.${form.address.country}.`}
                      optionsLabel={getTranslation("account.profile.form.state.options.label")}
                      options={form.address?.country === "CA" ? stateCa : form.address?.country === "US" ? stateUs : []}
                      width='307px'
                      value={form?.address?.region}
                      required
                      error={errors?.state ? errors.state : null}
                    />
                    : null
                }
              </>
              : form.type === 'EDUCATIONAL' ?
                <>
                  <CustomSelect
                    testdataId="profile-campus-role-select"
                    name='educationalRole'
                    setValue={handleChange}
                    label={getTranslation("account.profile.form.campusRole.title")}
                    translationPrefix='account.profile.form.campusRole.options.'
                    optionsLabel={getTranslation("account.profile.form.campusRole.options.label")}
                    options={campusRole}
                    width='307px'
                    value={form?.educationalRole}
                    required
                    error={errors?.educationalRole ? errors.educationalRole : null}
                  />
                  <CustomSelect
                    testdataId="profile-campus-discipline-select"
                    name='educationalDiscipline'
                    setValue={handleChange}
                    label={getTranslation("account.profile.form.educationalDiscipline.title")}
                    translationPrefix='account.profile.form.educationalDiscipline.options.'
                    optionsLabel={getTranslation("account.profile.form.educationalDiscipline.options.label")}
                    options={campusDiscipline}
                    width='307px'
                    value={form?.educationalDiscipline}
                    required
                    error={errors?.educationalDiscipline ? errors.educationalDiscipline : null}
                  />
                  <CustomInput
                    testdataId="profile-educational-organization-select"
                    name='educationalOrganization'
                    setValue={handleChange}
                    label={getTranslation("account.profile.form.educationalOrganization.title")}
                    width='307px'
                    value={form?.educationalOrganization}
                    required
                    error={errors?.educationalOrganization ? errors.educationalOrganization : null}
                  />
                </>
                : null
          }
          <CustomInput
            testdataId="profile-phone-number-input"
            name='phoneNumber'
            setValue={handleChange}
            label={getTranslation("account.profile.form.phoneNumber.title")}
            value={form.phoneNumber}
            required={form.type !== "EDUCATIONAL"}
            error={errors?.phoneNumber ? errors.phoneNumber : null}
          />
          {
            form.type === "EDUCATIONAL" && form.educationalRole === "STUDENT" ?
              <div className='whitespace-break-spaces space-y-4 text-xs text-gray-500'>
                {
                  !user?.ageConfirmation ?
                    <CustomCheckbox
                      testdataId="profile-age-confirmation-checkbox"
                      name="ageConfirmation"
                      label={getTranslation("account.profile.form.ageConfirmation.title")}
                      value={form?.ageConfirmation}
                      setValue={handleChange}
                      error={errors?.ageConfirmation}
                    /> : null
                }
                <CustomCheckbox
                  testdataId="profile-marketing-consent-checkbox"
                  name="marketingConsent"
                  label={getTranslation("account.profile.form.marketingConsent.title")}
                  value={form?.marketingConsent}
                  setValue={handleChange}
                  error={errors?.marketingConsent}
                />
              </div>
              : null
          }
        </div>
        <div className='flex flex-row space-x-2 justify-end '>
          <CustomButton
            testdataId="profile-reset-button"
            label={getTranslation("account.profile.form.buttons.cancel")}
            type='secondary'
            onClick={onReset}
          />
          <CustomButton
            testdataId="profile-save-button"
            label={getTranslation("account.profile.form.buttons.save")}
            type="primary"
            onClick={onSave}
            disabled={!saveEnabled}
          />
        </div>
      </form>
    </div>
  )
}

export default ProfileColumnProfile;
import { createContext, useContext, useEffect, useState } from "react";
import en_US from "../../assets/translations/en_US.json";
import cs_CZ from "../../assets/translations/cs_CZ.json";
import de_DE from "../../assets/translations/de_DE.json";
import es_ES from "../../assets/translations/es_ES.json";
import fr_FR from "../../assets/translations/fr_FR.json";
import hu_HU from "../../assets/translations/hu_HU.json";
import it_IT from "../../assets/translations/it_IT.json";
import ja_JP from "../../assets/translations/ja_JP.json";
import ko_KR from "../../assets/translations/ko_KR.json";
import nl_NL from "../../assets/translations/nl_NL.json";
import pl_PL from "../../assets/translations/pl_PL.json";
import pt_BR from "../../assets/translations/pt_BR.json";
import pt_PT from "../../assets/translations/pt_PT.json";
import ru_RU from "../../assets/translations/ru_RU.json";
import zh_CN from "../../assets/translations/zh_CN.json";
import zh_TW from "../../assets/translations/zh_TW.json";
import Cookies from "js-cookie";

interface ContextProps {
    readonly language: string;
    readonly setLocale: (locale: string) => void;
    readonly getTranslation: (key: string) => string;
    readonly getTranslationWithParameters: ({ key, parameters }: { key: string, parameters?: Object }) => string;
}

const languages = [
    "en_US",
    "cs_CZ",
    "de_DE",
    "es_ES",
    "fr_FR",
    "hu_HU",
    "it_IT",
    "ja_JP",
    "ko_KR",
    "nl_NL",
    "pl_PL",
    "pt_BR",
    "pt_PT",
    "ru_RU",
    "zh_CN",
    "zh_TW"
]

const translations = {
    en_US,
    cs_CZ,
    de_DE,
    es_ES,
    fr_FR,
    hu_HU,
    it_IT,
    ja_JP,
    ko_KR,
    nl_NL,
    pl_PL,
    pt_BR,
    pt_PT,
    ru_RU,
    zh_CN,
    zh_TW
}

const LocalizationContext = createContext<ContextProps>({
    language: languages[0],
    setLocale: () => { },
    getTranslation: () => "",
    getTranslationWithParameters: () => ""
});

export const useLocalization = () => {
    return useContext(LocalizationContext)
}

export const LocalizationProvider = ({ children }: { children: React.ReactNode }) => {

    let cookie_param_locale = "locale";
    const [language, setLanguage] = useState<string>(languages[0]);

    /**
     * Allows any type of parameters to avoid empty string instantiation during null checks.
     * Any non-string parameters are changed to NOT_SUPPORTED_VALUE.
     * TBD: Decide if it should be an empty string instead.
     */
    const getTranslationWithParameters = ({
        key,
        parameters
    }: {
        key: string,
        parameters?: Object
    }): string => {
        //
        var translation;
        try {
            //
            translation = (translations as any)[language][key];
        } catch (err) {
            //ignore silently if not found
        }
        //
        if (translation === undefined || translation === null) {
            // if not found, returns default language translation
            try {
                //
                translation = (translations as any)[languages[0]][key];
            } catch (err) {
                //ignore silently if not found
            }
        }
        //
        if (translation !== undefined && translation !== null) {
            //
            if (parameters) {
                //
                for (const [key, value] of Object.entries(parameters)) {
                    //
                    translation = translation.replaceAll("${" + key + "}", value);
                }
            }
        } else {
            //
            translation = key;
        }
        return translation
    }

    const getTranslation = (key: string) => {
        return getTranslationWithParameters({ key: key });
    }

    /**
     * Loads current locale from cookies.
     */
    useEffect(() => {
        //
        let localeFromCookies = Cookies.get(cookie_param_locale);
        if (localeFromCookies !== undefined && localeFromCookies !== null && languages.includes(localeFromCookies)) {
            //
            setLanguage(localeFromCookies);
            //
            // set language to universal navigation and footer
            //
            let languageTag = localeFromCookies;
            if (localeFromCookies === "ja_JP") {
                //
                languageTag = "jp_JP";
            }
            if (localeFromCookies === "en_US") {
                //
                languageTag = "en";
            }
            //
            const header = document.getElementById("tekla-navigation");
            if (header) {
                //
                header.setAttribute("data-language", languageTag);
            }
            //
            const footer = document.getElementById("trimble-common-footer");
            if (footer) {
                //
                footer.setAttribute("data-language", languageTag);
            }
        }
        // add universal scripts after setting correct language
        const headerScript = document.createElement("script");
        headerScript.src = "https://nav.prod.tekla.com/main.js";
        headerScript.defer = true;
        headerScript.type = "text/javascript";
        document.head.appendChild(headerScript);
        const footerScript = document.createElement("script");
        footerScript.src = "https://cookies.prod.tekla.com/main.js";
        footerScript.async = true;
        document.head.appendChild(footerScript);
        return () => {
            document.head.removeChild(headerScript);
            document.head.removeChild(footerScript);
        }
    }, [])

    /**
     * Set current locale to cookies.
     */
    const setLocale = (locale: string) => {
        //
        let domainToSet = (window.location.hostname !== "localhost" ? ".tekla.com" : "localhost")
        setLanguage(locale);
        Cookies.set(
            cookie_param_locale,
            locale,
            {
                domain: domainToSet,
                expires: 365
            }
        );
        window.location.reload();
    }

    const value = {
        language,
        setLocale,
        getTranslation,
        getTranslationWithParameters
    }

    return (
        <LocalizationContext.Provider value={value}>
            {children}
        </LocalizationContext.Provider>
    );
};
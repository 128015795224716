import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LocalizationProvider } from './components/providers/LocalizationProvider';

declare global {
  interface Window {
    ENV: {
      ADMIN_TOOL_URL: string;
      PROFILES_URL: string;
      RETURN_WHITELIST_PATTERN: string;
      RETURN_BLACKLIST_PATTERN: string;
      OAUTH_CLIENT_ID: string;
      OAUTH_LOGIN_REDIRECT_URI: string;
    }
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <LocalizationProvider>
      <App />
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

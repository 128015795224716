import { AdminUser } from "../../../../../model/AdminUser";
import { OrganizationGroup } from "../../../../../model/OrganizationGroup";
import { OrganizationRole } from "../../../../../model/OrganizationRole";
import { ChangeEvent, useEffect, useState } from "react";
import { Organization } from "../../../../../model/Organization";
import CustomModal from "../../../../custom/CustomModal";
import CustomButton from "../../../../custom/CustomButton";
import CustomSelect from "../../../../custom/CustomSelect";
import { useNotification } from "../../../../providers/NotificationProvider";
import { cancelMembershipRequest, getOrganizationAdmins, sendMembershipRequest } from "../../../../../assets/api";
import { MembershipRequest } from "../../../../../model/MembershipRequest";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "../../../../providers/LocalizationProvider";

export function OrganizationColumnOrganization(
  { employeesGroup, roles, groups, suggestedOrganizations, membershipRequests }:
    { employeesGroup: OrganizationGroup, roles?: Array<OrganizationRole>, groups?: Array<OrganizationGroup>, suggestedOrganizations: Array<Organization>, membershipRequests: Array<MembershipRequest> }) {

  const navigate = useNavigate();
  const { getTranslation, getTranslationWithParameters } = useLocalization();
  const { sendNotification } = useNotification();
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [modalTab, setModalTab] = useState<string>("employees");
  const [pendingRequest, setPendingRequest] = useState<MembershipRequest | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [admins, setAdmins] = useState<AdminUser[]>([]);
  const filteredSuggestedOrganizations = suggestedOrganizations?.filter((org) => org?.id !== employeesGroup?.organizationId);

  const openModal = () => {
    setIsModalVisible(true);
  }

  const closeModal = () => {
    setModalTab("employees");
    setSelectedOrganization(null);
    setIsModalVisible(false);
  }

  const getMyRolesInOrganization = () => {
    let retValue = new Array<string>();
    // Add Employee role as every employee has it
    retValue.push("Employee");
    if (roles && Array.isArray(roles)) {
      roles?.forEach((obj) => {
        if (employeesGroup?.organizationId === obj.organizationId) {
          retValue.push(obj.name)
        }
      });
    }
    if (groups && Array.isArray(roles)) {
      groups?.forEach((obj) => {
        if (employeesGroup?.organizationId === obj.organizationId && obj.type !== "employees") {
          retValue.push(obj.type !== "licenseConsumers" ? obj.name : "External license user")
        }
      });
    }
    retValue = retValue?.map((role) => (
      role
        .replace(/([A-Z])/g, ' $1')
        .trim()
    ))
    return retValue.join(', ');
  }

  const handleChangeOrgToRequest = async (e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as any;
    setSelectedOrganization(filteredSuggestedOrganizations[value]);
  }

  const sendRequest = async (e: React.FormEvent<HTMLButtonElement>) => {
    //
    e.preventDefault();
    setIsModalVisible(false);
    try {
      if (selectedOrganization != null) {
        //
        let response = await sendMembershipRequest(selectedOrganization.id, modalTab);
        if (response.status === 200) {
          sendNotification(getTranslation("account.organization.changeMembershipModal.notifications.sendSuccess"), "success");
          navigate('.', { replace: true })
        } else {
          let errorDescription = (await response.json() as any)?.error_description;
          sendNotification(errorDescription ? errorDescription : getTranslation("account.organization.changeMembershipModal.notifications.sendFailed"), "error");
        }
      }
    } catch (err) {
      sendNotification(getTranslation("account.organization.changeMembershipModal.notifications.sendFailed"), "error");
    }
    setSelectedOrganization(null);
    setModalTab("employees");
  }

  const cancelRequest = async (e: React.FormEvent<HTMLButtonElement>) => {
    //
    e.preventDefault();
    setIsModalVisible(false);
    try {
      if (pendingRequest != null) {
        //
        let response = await cancelMembershipRequest(pendingRequest.organization.id, pendingRequest.id);
        if (response.status === 200) {
          sendNotification(getTranslation("account.organization.changeMembershipModal.notifications.cancelSuccess"), "info");
          navigate('.', { replace: true })
        } else {
          let errorDescription = (await response.json() as any)?.error_description;
          sendNotification(errorDescription ? errorDescription : getTranslation("account.organization.changeMembershipModal.notifications.cancelFailed"), "error");
        }
      }
    } catch (err) {
      sendNotification(getTranslation("account.organization.changeMembershipModal.notifications.cancelFailed"), "error");
    }
  }

  useEffect(() => {
    //
    const foundRequest = membershipRequests?.find(obj => obj?.pending);
    if (foundRequest) {
      //
      setPendingRequest(foundRequest)
    } else {
      //
      setPendingRequest(null);
      setSelectedOrganization(null);
    };
  }, [membershipRequests])

  useEffect(() => {
    //
    const loadAdmins = async () => {
      if (employeesGroup) {
        const response = await getOrganizationAdmins(employeesGroup.organizationId);
        const result = response?.status === 200 && response?.headers?.get('content-type') === "application/json" ? await response.json() : null;
        setAdmins(result);
      }
    }
    loadAdmins();
  }, [])

  useEffect(() => {
    //
    if (selectedOrganization) {
      var el = document.getElementById("membership-request-tab");
      if (el) {
        el.onclick = function () {
          setModalTab("licenseConsumers");
        }
      }
    }
  }, [selectedOrganization])

  return (
    <div
      id='organization-column-organization'
      className='flex flex-col gap-4 justify-center h-full flex-full md:w-[720px] text-gray-600'
    >
      {
        pendingRequest === null ?
          <CustomModal
            label={getTranslation("account.organization.changeMembershipModal.title")}
            description={
              <div>
                {
                  modalTab === "employees" ?
                    filteredSuggestedOrganizations?.length > 0 ?
                      <>
                        <CustomSelect
                          label=""
                          optionsLabel={getTranslation("account.organization.changeMembershipModal.options.label")}
                          options={filteredSuggestedOrganizations}
                          optionsKey="name"
                          setValue={handleChangeOrgToRequest}
                          value={filteredSuggestedOrganizations.findIndex(obj => selectedOrganization?.id === obj.id)?.toString()}
                          useIndexAsValue={true}
                        />
                        <div className="mt-8 space-y-6">
                          <p>{getTranslationWithParameters({
                            key: "account.organization.changeMembershipModal.description_1",
                            parameters: {
                              organizationName: employeesGroup.organizationName
                            }
                          })}</p>
                          {
                            selectedOrganization ?
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: getTranslationWithParameters({
                                    key: "account.organization.changeMembershipModal.description_2",
                                    parameters: {
                                      organizationName: selectedOrganization.name,
                                      requestLinkStart: '<a id="membership-request-tab" style="cursor: pointer;">',
                                      linkEnd: '</a>'
                                    }
                                  })
                                }}
                              />
                              : null
                          }
                        </div>
                      </>
                      : <div>
                        <p>
                          {getTranslation("account.organization.changeMembershipModal.noOrganizationsToRequest_1")}
                        </p>
                        <p>
                          {getTranslation("account.organization.changeMembershipModal.noOrganizationsToRequest_2")}
                        </p>
                      </div>
                    : modalTab === "licenseConsumers" ?
                      <div>
                        {
                          selectedOrganization ?
                            <p
                              dangerouslySetInnerHTML={{
                                __html: getTranslationWithParameters({
                                  key: "account.organization.changeMembershipModal.joinAsExternalDescription",
                                  parameters: {
                                    organizationName: selectedOrganization.name
                                  }
                                })
                              }}
                            />
                            : null
                        }
                      </div>
                      : null
                }
              </div>
            }
            isVisible={isModalVisible}
            closeModal={() => closeModal()}
            buttons={[
              <CustomButton
                key="cancel"
                label={getTranslation("account.organization.changeMembershipModal.buttons.cancel")}
                type="secondary"
                onClick={() => closeModal()}
              />,
              <CustomButton
                key="submit"
                label={getTranslation("account.organization.changeMembershipModal.buttons.sendRequest")}
                type="primary"
                onClick={sendRequest}
                disabled={!selectedOrganization}
              />
            ]}
          />
          :
          <CustomModal
            label={getTranslation("account.organization.changeMembershipModal.title")}
            description={
              <div className="space-y-6">
                <p>{getTranslation("account.organization.changeMembershipModal.pending_description")}</p>
                <div className="flex flex-row space-x-4 items-center justify-center">
                  <span className="font-bold">{pendingRequest?.organization?.name}</span>
                  <CustomButton
                    label={getTranslation("account.organization.changeMembershipModal.buttons.cancel")}
                    type="secondary"
                    onClick={cancelRequest}
                  />
                </div>
              </div>
            }
            isVisible={isModalVisible}
            closeModal={() => setIsModalVisible(false)}
            buttons={[]}
          />
      }
      <div className='flex flex-col space-y-8'>
        <div className='flex flex-row items-center space-x-20'>
          <h2
            testdata-id='my-organization-organization-name-header'
            className='text-4xl text-gray-500'
          >
            {employeesGroup.organizationName}
          </h2>
          <div
            testdata-id='my-organization-need-to-change-button'
            className='text-xl text-trimble-blue cursor-pointer hover:text-trimble-orange'
            onClick={openModal}
          >
            {getTranslation("account.organization.needToChangeLink")}
          </div>
        </div>
        <div className='flex flex-row space-x-2'>
          <div
            className='font-extrabold text-gray-600'
          >
            {getTranslation("account.organization.yourRoles")}:
          </div>
          <div>{getMyRolesInOrganization()}</div>
        </div>
        <div>
          {getTranslationWithParameters({
            key: "account.organization.administratorsIn",
            parameters: {
              organizationName: employeesGroup.organizationName
            }
          })}
        </div>
        <div
          className='border-2 pl-1 border-gray-400 text-trimble-blue max-h-32 overflow-auto flex flex-col'
        >
          {
            admins?.sort((a, b) => a.firstName.localeCompare(b.firstName)).map((o: any, i: number) => <a key={'admin-' + i} href={'mailto:' + o.email} className="hover:text-trimble-orange">{o.firstName} {o.lastName}</a>)
          }
        </div>
      </div>
    </div>
  )
}

export default OrganizationColumnOrganization;
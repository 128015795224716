import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from './router';

const router = createBrowserRouter(routes);

function App() {
  return (
    <div id='main-container'>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
